import { encodeRoutePath } from '@/util/route-patterns';

export type Monogramable = Readonly<
  Partial<Record<'name' | 'nickname' | 'given_name' | 'family_name', string | null | undefined>>
>;

const mkInitials = (...names: readonly (string | null | undefined)[]) =>
  names
    .map(n => n && n[0])
    .filter(n => !!n)
    .join('')
    .toUpperCase();

const getInitials = (u: Monogramable) => {
  let names: [string | null | undefined, string | null | undefined];
  const nameOrNick = u.name || u.nickname;
  if (u.family_name || u.given_name) {
    names = [u.given_name, u.family_name];
  } else if (nameOrNick) {
    const parts = nameOrNick.split(/\s+/);
    names = [parts.at(0), parts.at(-1)];
  } else {
    return null;
  }
  return mkInitials(...names) || null;
};

export const getMonogramUrl = (u: Monogramable | null | undefined) => {
  const initials = u && getInitials(u);
  return encodeRoutePath(['monogram', { param: 'code' }], { code: initials || '??' });
};
