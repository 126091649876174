'use client';

import { useMemo } from 'react';

import { useSessionInfo } from '@/auth/client';

import AvatarView from './AvatarView';

export type Props = Omit<React.ComponentProps<typeof AvatarView>, 'user' | 'status'>;

export default function AvatarSelf(props: Props) {
  const { data: session, status } = useSessionInfo();
  const user = useMemo(() => (session?.user ? { sub: session.user.id, ...session.user } : null), [session?.user]);
  return <AvatarView {...props} user={user} status={status} />;
}
