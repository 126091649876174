import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/srv/app/.yarn/__virtual__/next-virtual-cd94814966/0/cache/next-npm-15.2.3-06a6671f62-91714227d0.zip/node_modules/next/dist/client/app-dir/link.js");
;
import(/* webpackMode: "eager" */ "/srv/app/.yarn/__virtual__/next-virtual-cd94814966/0/cache/next-npm-15.2.3-06a6671f62-91714227d0.zip/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/srv/app/packages/web-ui/src/components/avatar/AvatarSelf.client.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SuspenseOrAuthBoundary"] */ "/srv/app/packages/web-ui/src/components/SuspenseOrAuthBoundary.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["BreadcrumbsItem","Breadcrumbs"] */ "/srv/app/packages/web-ui/src/lib/primer/Breadcrumbs.ts");
;
import(/* webpackMode: "eager" */ "/srv/app/packages/web-ui/src/lib/primer/index.ts");
