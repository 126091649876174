'use client';

import type { Session } from 'next-auth';
import type { SessionContextValue, UseSessionOptions } from 'next-auth/react';
import { useSession as useSession_ } from 'next-auth/react';
import { useEffect } from 'react';

export { SessionProvider } from 'next-auth/react';

import { useCustomMemo } from '@/components/useCustomMemo';
import type { PickFromUnion } from '@fidant-io/util';
import { sortedStringify } from '@fidant-io/util/sorted-json';

import { authUrl, PagePaths } from './public-config';
import type { FidantClientSession } from './types';

export { authUrlOrigin, authUrlPath, PagePaths } from './public-config';

type WithFidantSession<T> = T extends { data: Session } ? Omit<T, 'data'> & { data: FidantClientSession } : T;
export type FidantSessionContextValue<R extends boolean = false> = WithFidantSession<SessionContextValue<R>>;

// See next-auth/src/react/index.tsx for the default implementation.
// This properly builds the URL, and does not hard-code the path.
// The default implementation does not respect the page routes defined in the config: it hard-codes `/api/auth/signin`.
const defaultOnUnauthenticated = () => {
  // NOTE: the actual host name is not used here, just the path.
  const u = new URL(PagePaths.signIn, authUrl);
  u.searchParams.set('error', 'SessionRequired');
  u.searchParams.set('callbackUrl', window.location.href);
  window.location.href = `${u.pathname}${u.search}`;
};

/**
 * Get the session context, returning 'unauthenticated' if not logged in, instead of brokenly redirecting to the wrong
 * login page if not logged in.
 */
export function useSession(options?: UseSessionOptions<false>): FidantSessionContextValue;
/**
 * Get the session context, of brokenly redirecting to the wrong login page if not logged in and no
 * `onUnauthenticated()` callback provided.
 */
export function useSession(options: UseSessionOptions<true>): FidantSessionContextValue<true>;
/**
 * Get the session context, either returning 'unauthenticated' if not logged in, or brokenly redirecting to the wrong
 * login page, or calling `onUnauthenticated()`, depending on arguments.
 */
export function useSession<R extends boolean>(options?: UseSessionOptions<R>): FidantSessionContextValue<R>;
export function useSession<R extends boolean = false>(options?: UseSessionOptions<R>): FidantSessionContextValue<R> {
  // The default implementation does not respect the URL routes defined in the config: it hard-codes `/api/auth/signin`.
  // Let's fix that.
  const { required, onUnauthenticated } = options ?? {};
  const value = useSession_() as FidantSessionContextValue<R>;

  const requiredAndNotLoading = required && value.status === 'unauthenticated';

  useEffect(() => {
    if (requiredAndNotLoading) {
      (onUnauthenticated ?? defaultOnUnauthenticated)();
    }
  }, [requiredAndNotLoading, onUnauthenticated]);

  if (requiredAndNotLoading) {
    return {
      data: value.data,
      update: value.update,
      status: 'loading',
    } as FidantSessionContextValue<R>;
  }

  return value;
}

/**
 * Get the session info, which is everything but the `update()` function.
 */
export type FidantSessionInfo = PickFromUnion<FidantSessionContextValue, 'status' | 'data'>;

/**
 * Get the stable/immutable session info, cached structurally.
 */
export const useSessionInfo = (): FidantSessionInfo => {
  const session_ = useSession();
  return useCustomMemo(
    ({ status, data }) => ({ status, data }) as FidantSessionInfo,
    ([a], [b]) =>
      a === b || (a.status === b.status && (a.data === b.data || sortedStringify(a.data) === sortedStringify(b.data))),
    [session_]
  );
};
