'use client';

import { Avatar, Tooltip } from '@/lib/primer';
import type { UserInfo } from '@fidant-io/auth-core-adapter/oidc';

import { getMonogramUrl } from '../monogram-url';

export type Props = Omit<React.ComponentProps<typeof Avatar>, 'src' | 'alt'> & {
  user: UserInfo | null;
  showName?: boolean;
  status?: 'authenticated' | 'unauthenticated' | 'loading' | null;
  showStatus?: boolean;
  tooltipMode?: 'email' | 'debug' | 'none';
};

const defaultTooltipMode = process.env.NODE_ENV === 'development' ? 'debug' : 'none';

export default function AvatarView(props: Props) {
  const { user, status, showName = true, showStatus = true, tooltipMode = defaultTooltipMode, ...avatarProps } = props;
  const { name = '??', picture = getMonogramUrl(user) } = user ?? {};
  const tooltipText =
    tooltipMode === 'none' ? null : tooltipMode === 'debug' ? JSON.stringify(user, null, 2) : user?.email || null;
  // The role="button" is required because the Tooltip component requires a single interactive child.
  const content = (
    <span role="button">
      <Avatar src={picture} alt={`@${name}`} {...avatarProps} />
      {showName && <>&nbsp;{name}</>}
      {showStatus && status && status !== 'authenticated' && <> ({status})</>}
    </span>
  );
  if (!tooltipText) {
    return content;
  }
  return (
    <Tooltip text={tooltipText} direction="sw">
      {content}
    </Tooltip>
  );
}
